.pageContainer {
  & > div:not(:last-child) {
    margin-bottom: 35px;
  }
}

.boxesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}
