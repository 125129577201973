@import "assets/style/vars.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 70px 15px 40px 0;
}

.currentPage {
  opacity: 0.3;
  font-size: 15px;
  font-weight: 700;
}
