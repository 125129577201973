@import "assets/style/vars";

.input {
  border: none;
  border-bottom: 1px solid $DARK_GREY;
  outline: none;
  font-size: 24px;
  color: $DARK_GREY;
  padding: 10px;
  text-align: center;
}
