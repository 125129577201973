@import "./vars.scss";

.card {
  position: relative;
  background-color: #fff;
  border-radius: 30px;
  padding: 24px 0 30px 0;
}

.img {
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.textTop {
  margin-bottom: 24px;

  .word {
    font-size: 30px;
    color: $BRAND_COLOR;
  }
  .wordTranslation {
    font-size: 20px;
    color: $DARK_GREY;
  }
  .wordTranslation_big {
    font-size: 30px;
    color: $DARK_GREY;
  }
}

.textBottom {
  padding: 30px 20px 0 20px;

  font-size: 20px;
  color: $DARK_GREY;

  /*Should I use sentences larger than two lines?*/
  /*min-height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;*/
}
