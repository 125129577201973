@import "assets/style/vars.scss";

.card {
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;

  border-radius: 12px;
  margin-bottom: 12px;

  cursor: pointer;
  overflow: hidden;
}

/* Cards`s left part */
.img {
  flex: 0 1 45%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* Cards`s right part */
.text {
  flex: 0 1 47%;
  padding: 10px;
  text-align: left;
}

.textTop {
  .word {
    font-size: 30px;
    color: $BRAND_COLOR;
  }
}

.textBottom {
  display: flex;
  font-size: 20px;
  color: $DARK_GREY;

  .phonetics {
    margin-right: 15px;
    position: relative;
  }
  .phonetics::after {
    content: "-";
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
  }
}
