@import "assets/style/vars.scss";

.buttons {
  display: flex;
  & :not(:last-child) {
    margin-right: 10px;
  }
}

.repeatTimeBtn {
  flex: 1 1;
  padding: 20px 10px;
  background-color: transparent;
  border: 1px solid $ACCENT_GREY;
  cursor: pointer;

  font-size: 18px;
  color: $DARK_GREY;
  border-radius: 12px;
}
