@import "./vars.scss";

.formContainer {
  border-radius: $BORDER_RADIUS;
  padding: 60px 20px 380px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formTitle {
  font-weight: 500;
  & span {
    color: $BRAND_COLOR;
    font-weight: 700;
  }
}
.formSubTitle {
  font-weight: 500;
  margin-top: 30px;
}

.form {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-self: center;

  & > *:last-child {
    margin-top: 50px;
  }
}
