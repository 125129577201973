@import "assets/style/vars.scss";

.itemContainer {
  flex: 0 1 47%;
  min-height: 100px;
  border-radius: $BORDER_RADIUS;
  background-color: white;
  box-sizing: border-box;
  padding: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.itemLink {
  display: block;
  min-height: 100%;
  min-width: 100%;
  z-index: 400;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
}

.itemImg {
  max-height: 200px;
  text-align: center;
  & img {
    max-width: 100%;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
  }
}

.itemText {
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
}
