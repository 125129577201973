@import "assets/style/vars.scss";

.container {
  background-color: white;
  padding: 30px 50px;
  border-radius: $BORDER_RADIUS_MIN;
  flex: 1 1 auto;
}

.contentTop {
  color: $BRAND_COLOR;
  font-size: 40px;
  margin-bottom: 10px;
}

.contentBottom {
  font-size: 30px;
  color: $DARK_GREY;
}
