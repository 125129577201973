.container {
  text-align: left;
  & section > div:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

section:not(:last-of-type) {
  margin-bottom: 70px;
}

h1 {
  margin-bottom: 30px;
}
