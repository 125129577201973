@import "assets/style/vars.scss";

.userContainer {
  height: 100px;
  display: flex;
  justify-content: space-between;
}

.userStats {
  flex: 0 1 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;

  .statsTitle {
    font-size: 32px;
    font-weight: 500;
    & span {
      font-weight: 600;
    }
  }
  .statsBarContainer {
    width: 100%;
  }
  .statsBar {
    width: 100%;
    height: 5px;
    background-color: $BRAND_COLOR;
    border-radius: 10px;
  }
  .statsBarText {
    margin-top: 7px;
    font-size: 12px;
    text-align: left;
    color: $DARK_GREY;

    & span {
      color: $BRAND_COLOR;
      font-size: 18px;
      line-height: 1;
    }
  }
}

.userImg {
  flex: 0 1 100px;
  background-color: #f9d6bc;
  border-radius: 30px;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
    height: 200%;
    object-fit: contain;
    position: absolute;
    top: 10%;
    left: 0;
  }
}
