@import "assets/style/vars.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 30px 15px 20px 15px;
}

.settingsButton {
  height: 30px;
  width: 30px;
  mask-image: url("../../../public/img/ui-images/settings.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: $DARK_GREY;
}

.burgerButton {
  height: 15px;
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  z-index: 510;

  & span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: $DARK_GREY;
    transition: all 0.7s;
  }
  & span:nth-child(2) {
    width: 75%;
  }
}
.burgerButtonActive {
  position: relative;

  & span {
    background-color: white;
  }
  & span:nth-child(1) {
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
  }
  & span:nth-child(2) {
    display: none;
  }
  & span:nth-child(3) {
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  transform: translateX(-100%);
  transition: transform 0.5s;
  z-index: 500;

  background-color: #0c2568;
  font-size: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & ul :not(:last-child) {
    margin-bottom: 40px;
  }
  & a {
    color: white;
    transition: color 0.3s;
  }
  & a:hover {
    color: $BRAND_COLOR;
  }
}
.menuActive {
  transform: translateX(0);
}

.title {
  font-size: 100px;
  line-height: 1;
  margin-bottom: 80px;
  & a {
    color: $BRAND_COLOR;
    font-weight: 600;
  }
}
