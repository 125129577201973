@import "assets/style/vars.scss";

.container {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  border-radius: $BORDER_RADIUS_MIN;
  display: flex;
  justify-content: space-between;

  & :not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.link {
  width: 100%;
  height: 100%;
}

.articleNum {
  color: $BRAND_COLOR;
  font-size: 80px;
  font-weight: bold;
  line-height: 1;
  opacity: 0.3;
  flex: 0 0 27%;
  border-right: 4px solid $BRAND_COLOR;
}
.articleHeading {
  font-size: 20px;
  flex: 0 0 67%;
}
