@import "assets/style/vars.scss";

.link {
  margin-top: 30px;
  border: none;
  background-color: $BRAND_COLOR;
  color: white;
  padding: 15px 60px;
  font-size: 18px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: $BRAND_COLOR_HOVER;
  }
}
