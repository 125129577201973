.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & p {
    opacity: 0.3;
    font-size: 15px;
    font-weight: 700;
  }
}
