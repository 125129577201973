@import "assets/style/cardCommon.module";

.buttons {
  margin-top: 40px;
}

.showAnswerBtn {
  padding: 20px 40px;
  width: 100%;
  background-color: transparent;
  border: 1px solid $ACCENT_GREY;
  cursor: pointer;

  font-size: 18px;
  color: $DARK_GREY;
  border-radius: 12px;
}

.emptyAlert {
  padding: 80px 70px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: $DARK_GREY;
}
